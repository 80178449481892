import React from "react"
import { graphql, Link } from "gatsby"

import Seo from "../components/seo"
import GatsbyImage from "gatsby-image"
import PageTitle from "../components/shared/PageTitle"
// import GoogleForm from "../components/googleForm"

const Divisions = ({ data }) => {
  const {
    slmhaLogo,
    allContentfulDivisionCategory: { nodes: categories },
  } = data

  return (
    <>
      <Seo
        title="Divisions"
        keywords={[`Sioux Lookout`, `hockey`, `Divisions`, `info`]}
      />
      <div className="pb-10 w-full grid px-3 justify-items-center">
        <div className=" w-full  flex flex-col items-center  max-w-7xl justify-items-center ">
          <PageTitle className="my-10  ">SLMHA Divisions</PageTitle>
          <ul className="grid text-center">
            {categories.map((category, index) => {
              return (
                <li key={`anchor-${category.title}-${index}`}>
                  <a href={`#${category.title}`}>{category.title}</a>
                </li>
              )
            })}
          </ul>
          {categories.map((category, index) => {
            return (
              <section className="grid mt-5" key={`${category?.id}-${index}`}>
                <h1 className=" font-bold" id={`${category?.title}`}>
                  {category.title}
                </h1>

                <div className="flex flex-wrap justify-center  text-2xl text-center break-words">
                  {category?.divisions?.map((division, index) => (
                    <Link
                      to={`/divisions/${division?.slug}`}
                      className=" flex flex-col justify-between items-center border p-5 shadow-lg rounded  no-underline transform hover:scale-110 ease-linear duration-75 m-4 w-full max-w-96 sm:w-96 min-w-max min-h-64"
                      key={`${index}-${division?.id}`}
                    >
                      <div className=" flex items-center flex-grow  ">
                        <GatsbyImage
                          fixed={
                            division?.divisionLogo?.fixed ||
                            slmhaLogo?.childImageSharp?.fixed
                          }
                        />
                      </div>
                      <div className=" no-underline mt-2 mx-1 text-primary font-heading ">
                        {division.divisionName}
                      </div>
                    </Link>
                  ))}
                </div>
              </section>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Divisions

export const pageQuery = graphql`
  query DivisionsQuery {
    allContentfulDivisionCategory(sort: { fields: weight }) {
      nodes {
        id
        title
        divisions {
          id
          divisionName
          weight
          divisionLogo {
            fixed(width: 200) {
              ...GatsbyContentfulFixed_tracedSVG
            }
          }
          slug
        }
      }
    }
    divisions: allContentfulDivision(sort: { fields: weight }) {
      nodes {
        id
        divisionName
        weight
        divisionLogo {
          fixed(width: 200) {
            ...GatsbyContentfulFixed_tracedSVG
          }
        }
        slug
      }
    }
    slmhaLogo: file(relativePath: { eq: "SLMHA_4x.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
